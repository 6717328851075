import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import '../../src/body/cart.css';
import '../header/headshop.css';
import '../../src/fonts/direction-icon.css';
import { getCartListAPI, getWishListAPI, getCountryListAPI,checkPopupForfirstTimeAPI } from "../Redux/Api";
import { useSelector, useDispatch } from 'react-redux'
import { updateCartStateValue, updateWishListStateValue, updatecountryStateValue,updatecheckFirstTimeStateValue } from "../Redux/Redux";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import TopMenu from "../../src/topmenu/index";
import { ReactComponent as Logo } from "../../src/images/megashop.svg";
import  LogoColour from "../../src/images/megashop.png";
import { jwtDecode } from "jwt-decode";
import { Modal, Button } from 'antd';
import   Float_offer  from "../../src/images/first-time-user.png";


const Ecomheader = () => {
  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    if (!storedCountry) {
        localStorage.setItem('selectedCountry', 'INDIA');
        localStorage.setItem('currencySymbol', '₹');
        localStorage.setItem('currencyName', 'INR');
        localStorage.setItem('mobileprefix', '+91');
        localStorage.setItem('mobilemaxlength', '10');
        setSelectedCountry('INDIA');
      setShowVoucherModal(true);
      // window.location.href = '/HomePage';
    }
    else {
      console.error(`Data for ${selectedCountry} not found in the countryList`);
    }
  }, []);
  const token = localStorage.getItem('token');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const [isModalVisibleforNotification, setIsModalVisibleforNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [checkFirstTime, setCheckFirstTime] = useState('');
  const [clientDataheader, setClientDataheader] = useState(null);
  
  const closeAlert = () => {
    setShowAlert(false);
  };
  const togglePopupVisibilityforNotification = () => {
    setIsModalVisibleforNotification(true);
    setShowAlert(true);
};

  useEffect(() => {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token has expired
        setIsTokenExpired(true);
        localStorage.removeItem('token');
      } else {
        // Token is still valid
        setIsTokenExpired(false);
      }
    }
    const timer = setTimeout(() => {
      setRenderComponent(true);
    }, 50);
  }, []);
  
  const [data, setData] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logoUrl, setLogoUrl] = useState('');
  const [searchList, setSearchList] = useState(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const wishListValue = useSelector((state) => state.wishlist.value);
  const cartValue = useSelector((state) => state.cart.value);
  const { width } = useWindowDimensions();
  const [searchTerm, setSearchTerm] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    const fetchDataforNotification = async () => {
        try {
            const response = await fetch('http://68.178.169.226:12090/api/v1/notification/get', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Use the token for authorization
                },
                body: JSON.stringify({
                    clientid : localStorage.getItem("clientid")
                }),
            });
            const data = await response.json();
            setNotificationData(data);
            setVisibleNotifications(data.slice(0, 4));
        } catch (error) {
            console.error('Error fetching notification data:', error);
        }
    };

      fetchDataforNotification();
}, [isModalVisibleforNotification]);
  const handleViewAll = () => {
    setVisibleNotifications(notificationData);
    navigate("/NotificationList")
};
  // Function to toggle the subcategory dropdown
  const togglePopupVisibility = () => {
    setIsPopupVisible(!isPopupVisible);
  };



  const getWishListCount = async () => {
    //Fetching data from Server for Wishlist
    if (localStorage.getItem('token')) {
      let wishListData = await getWishListAPI();
      dispatch(updateWishListStateValue(wishListData));
    }
    //Else update it from Local Storage
    else {
      dispatch(updateWishListStateValue(JSON.parse(localStorage.getItem('wishlist')) || []))
    }
  }

  const getCartCount = async () => {
    //Fetching data from Server for Wishlist
    if (localStorage.getItem('token')) {
      let cartData = await getCartListAPI();
      dispatch(updateCartStateValue(cartData));
    }
    //Else update it from Local Storage
    else {
      dispatch(updateCartStateValue(JSON.parse(localStorage.getItem('cart')) || []))
    }
  }
  // State to hold the cart count
  // const [cartCount, setCartCount] = useState(getCartCount());
  useEffect(() => {
    // Update cart count when the component mounts and whenever cartData changes
    getCartCount();
    getWishListCount();
    getCountryCount();

  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if(!localStorage.getItem('token')){
        return;
      }
      try {
        setLoading(true);
        const response = await fetch('http://68.178.169.226:12090/api/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const clientData = await response.json();
          setUserDetails(clientData); // Store user details in state
        } else {
          console.error('Failed to get client details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }finally{
        setLoading(false);
      }
    };

    // Call the function to fetch user details
    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();

    const currentURL = window.location.href;
    if (currentURL.includes('HomePage')) {
      window.location.reload(false)
    }
    else {
      navigate("/HomePage");
    }
  };

  const getCountryCount = async () => {
    try {
      setLoading(true);
      const data = await getCountryListAPI();
      dispatch(updatecountryStateValue(data));
      setCountryList(data)
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Click occurred outside the input element
        setSearchList(null);
        // Perform your desired action here
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleToggleClick = async () => {
    // Access the body element and toggle the className
    document.body.classList.toggle("mmenu-active");
  
    try {
      // Fetch categories from the API
      const categoryResponse = await axios.put("http://68.178.169.226:12090/api/v1/product/getcategoryAndsubcategory");
      if (categoryResponse.status === 401 || categoryResponse.status === 404) {
        return;
      }
  
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  
    // try {
    //   // Fetch user details from the API
    //   const userResponse = await fetch('http://68.178.169.226:12090/api/v1/login/getclientdetails', {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //     },
    //   });
  
    //   if (userResponse.ok) {
    //     // Store user details in state
    //   } else {
    //     console.error('Failed to get client details');
    //   }
    // } catch (error) {
    //   console.error('Error fetching user details:', error);
    // }
  };
  
  const handleInputChangeforSearchItem = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSubmitforSearchItem = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('search', searchTerm);
      formData.append('currency',localStorage.getItem('currencyName'))
      // Make the API call
      const response = await fetch(`http://68.178.169.226:12090/api/v1/product/search?search=${searchTerm}&currency=${localStorage.getItem('currencyName')}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        // body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      navigate('/SearchItems',{state: { searchData : data}});
      // Handle the API response data here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error.message);
    }
  };
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showBanner, setShowBanner] = useState(true);

  const handleBannerClose = () => {
    setShowBanner(false);
  };
  const getCheckFirstTimeCount = async () => {
    try {
      setLoading(true);
      const data = await checkPopupForfirstTimeAPI();
      dispatch(updatecheckFirstTimeStateValue(data));
      setCheckFirstTime(data)
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
    finally {
      setLoading(false);
    }
  };
  // const checkPopupForfirstTime = async () => {
  //   try {
  //     const response = await fetch('http://68.178.169.226:12090/api/v1/order/checkfirsttimebuyer', {
  //       method: 'POST',
  //       headers: {
  //         "Content-Type": "application/json",
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         clientid: localStorage.getItem('clientid')
  //       }),
  //     });
  //     if(response.ok) {
  //     const data = await response.json();
  //     setCheckFirstTime(data)
  //     }
  //   } catch (error) {
  //     // Handle error here
  //     console.error('Error:', error);
  //   }
  // }
  useEffect(() => {
    getCheckFirstTimeCount();
  }, []);
  
  return (
    <div  >{!loading && 
      <header className={`header`}>
        <div className="header-top">
        <div className="container d-flex justify-content-between">
            <div >
            <Link to="/HomePage">
            Seller login 
              </Link> 
              |    
              <Link to="/HomePage">
              Become Seller 
              </Link>
               
            </div>
            <div>
            {!(userDetails.firstname || userDetails.lastname) && (
                <Link to="/LoginPage">               
            <div className="riot-login">
            {/* <span>Sign In/Register</span>  */}
              Login/sign up
                  
                  </div>
                  </Link>
              )}
              {userDetails.firstname && (
     <div className=" riot-login">
    <Link to="/UserProfile">
    {userDetails.firstname +" "+  userDetails.lastname}
    </Link>
    </div>
  )}
            </div>
            
        </div>
        </div>
        <div className="header-middle">
          <div className="container">
            <div className="header-left">
            <a href="#" className="mobile-menu-toggle" onClick={handleToggleClick}>
<i className="d-icon-bars2"></i>
</a>
              <Link to="/HomePage">
                <Logo  alt="Logo" width="160"   className="top-logo " />
              </Link>
              <nav>
        <TopMenu/>
      </nav>
      
              <div className="header-search header-search-desktop hs-simple relative ml-2">
                <form action="#" className="input-wrapper" onSubmit={handleSubmitforSearchItem}>
                  <input type="text" className="form-control" name="search" autocomplete="off" placeholder="Search... "
                    value={searchTerm}  onChange={handleInputChangeforSearchItem}
                  />
                  <button className="btn btn-search" type="submit" title="submit-button ">
                    <i className="d-icon-search" ></i>
                  </button>
                </form>

              </div>
    

              {/* <Search list = {searchList} /> */}


            </div>
            

{selectedCountry && window.location.pathname === "/HomePage" && checkFirstTime===true && showBanner && (
  <div className="offer-banner">
    <Link to="/AccountCoupons">
    <img src={Float_offer}/>
    </Link>
    <button className="button-banner" onClick={() => handleBannerClose()}>X</button>
  </div>
)}
            <div className="flex  items-center px-2">
              <div className="dropdown">
            <div className=" riot-login notification">
                    <i className="r-icon r-icon-alert" onClick={togglePopupVisibilityforNotification}> </i>
                    {isModalVisibleforNotification && (
                <>
                
                {visibleNotifications.length > 0 ? (
    <ul className="dropdown-box">
        {visibleNotifications.map((notification, index) => (
            <li key={notification.notificationid} className="d-flex justify-content-between">
                <p className="align-self-center">{notification.type}</p>
                <button onClick={() => window.location.href = notification.link}>View</button>
            </li>
        ))}
        {notificationData.length > 4 && (
    <li>
        <button onClick={handleViewAll}>View All</button>
    </li>
)}
</ul>
) : (
  userDetails.firstname ?(''):( 
  
  <Modal
  visible={showAlert}
  onCancel={closeAlert}
  footer={[
      <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
          closeAlert();
          window.location.href = '/LoginPage';
      }}>
          Login
      </Button>,
  ]}
  >
  <p>Please Login to Proceed.</p>
  </Modal>)
)}

                </>
            )}
             </div>
              
              </div>
              <div className="flex mobile-cart-wishlist-riot">
              {/* <span>welcome {userDetails.firstname}</span> */}
              <div className=" mr-0  " >
              <div>
  {userDetails.firstname ? (
     <div className=" riot-login">
    <Link to="/UserProfile">
      <i className="r-icon r-icon-user"></i>
    </Link>
    </div>
  ) : null}
</div>

                {/* <div className="dropdown">
                      
                  {userDetails.firstname ? (
                    <div className=" riot-login">
                    <i className="r-icon r-icon-user" onClick={togglePopupVisibility}></i>
                    
                  </div>
                  
                  ) : null}
                  <ul className="dropdown-box">
                    <li>
                      <Link to="/UserProfile">{userDetails.firstname}(Profile)</Link>
                    </li>
                    <li>
                      <Link to="/AccountOrders">ORDERS</Link>
                    </li>
                    <li>
                    <Link to="/UserProfile">ADDRESS</Link> 
                    </li>
                    <li>
                      <Link to="/WishList">WISHLIST</Link>
                    </li>
                    <li>
                      <Link to="/AccountCoupons">COUPONS</Link>
                    </li>
                    <li>
                      <Link to="/HomePage" onClick={handleLogout}>
                        LOGOUT
                      </Link>
                    </li>

                  </ul>
                </div> */}

              </div>
               
              {!(userDetails.firstname || userDetails.lastname) && (
                <Link to="/LoginPage">               
            <div className="riot-login">
            {/* <span>Sign In/Register</span>  */}
            <i className="r-icon r-icon-user"></i>
                  
                  </div>
                  </Link>
              )}
              {/* </a> */}
               
                <Link to="/Cart">
                <div className="cart-dount">
                 <i className="r-icon r-icon-cart " onClick={() => navigate('/Cart')}></i>
                 <div className="header-wishlist-cart-count">{cartValue.length}</div>
                </div>
                </Link>

              </div>
            </div>
          </div>
        </div>
        {window.location.pathname === "/Cart"  || window.location.pathname === "/BuyCheckout" || window.location.pathname === "/Checkout" || window.location.pathname==="/LoginPage" || window.location.pathname==="/AddressAccount" || window.location.pathname==="/RegisterPopup" || window.location.pathname==="/UserProfile" || window.location.pathname==="/AccountOrders"
        || window.location.pathname==="/AccountCoupons" || window.location.pathname==="/ViewOrder"? (
      ''): (
        <div className="search-for-mobile">
        <div className="header-search hs-simple">
          <form action="#" className="input-wrapper" onSubmit={handleSubmitforSearchItem}>
                  <input type="text" className="form-control" name="search" autocomplete="off" placeholder="Search... "
                    value={searchTerm} onChange={handleInputChangeforSearchItem}
                  />
                  <button className="btn btn-search" type="submit" title="submit-button ">
                    <i className="d-icon-search" ></i>
                  </button>
                </form></div>
        </div>
      )}
         <div className={`header-bottom d-lg-show ${width > 800 ? "scale-[80%]" : ""}`}>
           
          
            
          
        </div>  
      </header>
 }
    
     

      {/* <Category/> */}
      {/* <Ecomfooter/> */}
    </div>
    

  );
}

export default Ecomheader