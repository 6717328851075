import axios from "axios";
import React, { useEffect, useState,Suspense} from "react";
import { useParams } from "react-router-dom";
import noimage from '../../src/images/noimage.jpg';
import Topimage from '../../src/images/store/top-image.jpg';

const Ecomfooter = React.lazy(() => import("../footer/footershop"));
const Ecomheader = React.lazy(() => import("../header/headershop"));
const ProductWrap = React.lazy(() => import("../../src/product/productWrap"));
const MobileMenu = React.lazy(() => import("../../src/navbarHead/mobileNav"));
const SellerProfileDetails = React.lazy(() => import("../component"));

const SellerProduts = () => {
    const token = localStorage.getItem("token");
    const { sellerid } = useParams();
    const [itemsForSeller, setItemsForSeller] = useState([]);
    const [responseitemsForSeller, setresponseItemsForSeller] = useState([]);
    const [itemsForSellerforClient, setItemsForSellerforClientDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorvalue, setErrorvalue] = useState(null);
    const [errorvalueforimg, setErrorvalueforimg] = useState(null);
    const [categoryNames, setCategoryNames] = useState([]);
    const [uniqueCategoryIds, setUniqueCategoryIds] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    useEffect(() => {
        const fetchProductsBySeller = async () => {
            try {
                setLoading(true);
                console.log(sellerid)
                const response = await axios.put(
                    'http://68.178.169.226:12090/api/v1/product/getbyseller',
                    { sellerid: sellerid, currency: localStorage.getItem('currencyName') },
                );
                if (response.status === 200) {
                    const items = response.data;
                    if(items.status){

                    }else{
                    setresponseItemsForSeller(items);
                    setItemsForSeller(items);
                    const ids = [...new Set(items.map(item => item.categoryid))];
                    setUniqueCategoryIds(ids);
                    
            const categoryNames = ids.map(categoryId => {
                // Find the item with the matching category ID
                const itemWithCategory = items.find(item => item.categoryid === categoryId);
                // Extract and return the category name
                
                return itemWithCategory ? itemWithCategory.categoryname : null;
                
            });
        
            setCategoryNames(categoryNames);
            setSelectedCategory("All");
                }
                if(response.data.status === "Error"){
                    setErrorvalue(response.data.description);
                    setErrorvalueforimg(response.data.organiserId);
                }}
            } catch (error) {
                console.error("Error:", error);
            }finally {
                setLoading(false);
            }
        };

        fetchProductsBySeller();
    }, [sellerid, token]);

    useEffect(() => {
        const fetchProductsBySellerforClienTDetails = async () => {
            try {
                const response = await axios.put(
                    'http://68.178.169.226:12090/api/v1/seller/sellerdetails/getforclient',
                    { sellerid: sellerid, currency: localStorage.getItem('currencyName') },
                );
                if (response.status === 200) {
                    const items = response.data;
                    setItemsForSellerforClientDetails(items);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchProductsBySellerforClienTDetails();
    }, [sellerid, token]);
    const handleCategoryClick = (categoryId) => {
        const filteredProducts = responseitemsForSeller.filter(item => item.categoryid === categoryId);
        setItemsForSeller(filteredProducts);
        setSelectedCategory(categoryId);
    };
    return (
        <div>
            <Suspense fallback={<div className="container-fluied"><div className="row"><div className="muti-col-loader"></div></div></div>}>
            <Ecomheader />
            <MobileMenu />
            <div className="container-fluied seller-bg" style={{ 
            backgroundImage: `url(${Topimage})`, }}>
            <div className="container">
            <SellerProfileDetails id={sellerid}/>
            
            </div>

            </div>
            
           
            {categoryNames && categoryNames.length > 1 && (
                <>
        <div className="row p-3 d-flex justify-content-center" aria-labelledby="">
            
                <div  className={`select-category-main ${selectedCategory=="All" ? 'category-selected' : 'category-unselected'}`} onClick={() => {setItemsForSeller(responseitemsForSeller);
                setSelectedCategory("All")}}>All</div>
            {categoryNames.map((categoryName, index) => (
                <div key={index} className={`select-category-main ${selectedCategory==uniqueCategoryIds[index] ? 'category-selected' : 'category-unselected'}`} onClick={() => handleCategoryClick(uniqueCategoryIds[index])}>{categoryName}</div>
            ))}
        </div>
        </>
)}
           {/* {categoryNames && (
                        <div>
                            <ul className="flex row" style={{justifyContent:"space-evenly"}}>
                                {categoryNames.map((categoryName, index) => (       
                <div className="col-md-3">
                                    <li key={index} onClick={() => handleCategoryClick(uniqueCategoryIds[index])}>{categoryName}</li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )}  */}
            <div className="container homebg seller-profile">
         {loading || !itemsForSeller  ?(
                   <div className="container-fluied">
                   <div className="row">
                     <div className="muti-col-loader"></div>
                   </div>
                 </div>
                ) : (
                    <>
       

     
                    <div className="row">
                    {itemsForSeller.length !== 0 ? ( 
                        itemsForSeller.map((item) => (
                                <ProductWrap key={item.productid} product={item} />
                        ))
                    ) : (
                        <h1 className="notavailable" style={{ display: !itemsForSeller ? 'none' : 'block' }}>
        <img src={errorvalueforimg}/>
        {errorvalue}
      </h1>
                    )}
                    </div>
                    </>
                )}
            </div>
            <Ecomfooter />
            
            </Suspense>
        </div>
    );
};

export default SellerProduts;
