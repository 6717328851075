import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileMenu from "../../src/navbarHead/mobileNav";
const MobileChange=()=>{
       const navigate = useNavigate();
        const [clientDetails, setClientDetails] = useState({});
        const [mobilenumber,setMobilenumber] = useState('');
        const [mobileotp,setMobileotp] = useState('');
        const [mobileotpverify,setMobileotpVerify] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        const token = localStorage.getItem("token");
      
        useEffect(() => {
          const fetchClientDetails = async () => {
            try {
              const response = await fetch("http://68.178.169.226:12090/api/v1/login/getclientdetails", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
      
              if (response.ok) {
                const data = await response.json();
                setClientDetails(data);
                setMobilenumber(data.mobile || '');
                 // Initialize editedClientDetails with clientDetails values
              } else {
                console.error("Failed to get client details");
              }
            } catch (error) {
              console.error("Error fetching client details:", error);
            }
          };
      
          fetchClientDetails();
        }, [token]);

              const sendOtptoMobile = async () => {
                if (mobilenumber.length < localStorage.getItem('mobilemaxlength')) {
                  // Show an error message for less than 10 digits
                  setErrorMessage('Please enter ' + localStorage.getItem('mobilemaxlength') + ' digits.');

                  return;
                }
                try {
                  const response = await fetch("http://68.178.169.226:12090/api/v1/client/mobileotpclient", {
                    method: "PUT",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: mobilenumber,
                    }),
                  });
            
                  if (response.ok) {
                    setMobileotpVerify(true)
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              const verifyOtptoMobile = async () => {
                try {
                  const response = await fetch("http://68.178.169.226:12090/api/v1/client/mobilechange", {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: mobilenumber,
                      otp: mobileotp.mobileotp,
                    }),
                  });
            
                  if (response.ok) {
                    alert("Mobile number changed successfully")
                    navigate(-1)
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              
              
              const handleMobileChange = (e) => {
                const newValue = e.target.value;
            
                // Check if the input consists of only digits and has a maximum length of 10 characters
                if (/^\d*$/.test(newValue) && newValue.length <= parseInt(localStorage.getItem('mobilemaxlength')))  {
                  setMobilenumber(newValue);
                  setErrorMessage('');
        
                }else {
                  // Set an error message when the input is invalid
                  setErrorMessage('Phone number must be a maximum of'  + localStorage.getItem('mobilemaxlength') + 'digits and contain only numbers.');
                }
              };
return(
  <div>
        <div>
            <Helmet>
                <title>Account</title>
            </Helmet>
        </div>
        <Ecomheader/>
        <MobileMenu/>
<main className="main account">
<nav className="breadcrumb-nav">
<div className="container">
<ul className="breadcrumb">
</ul>
</div>
</nav>
<div className="page-content mt-4 pb-6">
<div className="container homebg">
<h2 className="title title-center mb-10"></h2>
<div className="tab tab-vertical gutter-lg">
<div className="tab-content col-lg-12 col-md-12">
<div className="accountdetails">    
<div className="tab-pane" id="account">
<form action="#" className="form">
 {/* <div className="row">
<div className="col-sm-6">
<label>Name *</label>
<input type="text" className="form-control" name="first_name" required  value={editedClientDetails.firstname}  onChange={(e) =>
                              setEditedClientDetails({
                                ...editedClientDetails,
                                firstname: e.target.value,
                              })
                            }/>
</div>
</div>
<label>Display Name *</label>
<input type="text" className="form-control mb-0" name="display_name" required value={editedClientDetails.firstname}  onChange={(e) =>
                              setEditedClientDetails({
                                ...editedClientDetails,
                                firstname: e.target.value,
                              })
                            }/>
<button type="button" className="btn btn-dark btn-md btn-rounded" style={{marginTop:"10px",marginBottom:"15px"}} onClick={handleEditClientDetails}>
                       Submit Changes
                      </button>
<small className="d-block form-text mb-7">This will be how your name will be displayed
in the account section and in reviews</small> */}
<label>Email Address *</label>
<input type="email" className="form-control" name="email" required  value={clientDetails.email || ""}/>
<div className="col-sm-6">
  <label>Mobile Number *</label>
  <input
    type="text"
    className="form-control"
    name="mobile"
    required
    value={mobilenumber}  
    onChange={handleMobileChange} 
  />
  <div style={{ color: 'red' }}>{errorMessage}</div>
  {!mobileotpverify ?(
  <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={sendOtptoMobile}>
                       Edit Mobile Number
                      </button>):(
                      <div>
                      <input type="text" className="form-control" name="mobileotp" placeholder="otp" onChange={(e) =>
      setMobileotp({
        mobileotp: e.target.value,
      })
    } />
    <button type="button" className="btn btn-dark btn-md btn-rounded" style={{marginRight:"10%"}} onClick={sendOtptoMobile}>
                       Resend OTP
                      </button>
    <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={verifyOtptoMobile}>Verify</button>
                      </div>)
}
</div> 

</form>
</div>
</div>    
</div>
</div>

</div>
</div>
</main>   
<Ecomfooter/>
 </div>
    )
}

export default MobileChange;