import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { fetchCategories, fetchClientDetails } from "../api";

// Lazy-loaded components
const Ecomfooter = React.lazy(() => import("../footer/footershop"));
const Ecomheader = React.lazy(() => import("../../src/header/headershop"));
const Megashophome = React.lazy(() => import("../../src/banner/insex"));
const Megashopship = React.lazy(() => import("../../src/facillity/index"));
const Megashopourcato = React.lazy(() => import("../../src/homecategory/index"));
const Megashopourbest = React.lazy(() => import("../../src/bestSellers/index"));
const Megashopouroffer = React.lazy(() => import("../../src/offer/index"));
const FeaturedProduct = React.lazy(() => import("../../src/featurdproduct/index"));
const NavHead = React.lazy(() => import("../navbarHead"));
const MobileMenu = React.lazy(() => import("../../src/navbarHead/mobileNav"));
const Theme  = React.lazy(() => import("../../src/theme/index"));
const FirstTimeVoucher = React.lazy(() => import("../../src/theme/FirstTimeVoucher/index"));

const HomePage = () => {
    const [categories, setCategories] = useState([]);
    const [categories1, setCategories1] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMegashophomeVisible, setIsMegashophomeVisible] = useState(true);
    const [isThemeVisible, setIsThemeVisible] = useState(true);
    const toggleMegashophomeVisibility = () => {
      setIsMegashophomeVisible(!isMegashophomeVisible);
    };
    const toggleThemeVisibility = () => {
      setIsThemeVisible(!isThemeVisible);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            getClientDetails(token);
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const categoriesResponse = await fetchCategories();
            setCategories(categoriesResponse.slice(0, 8));
            setCategories1(categoriesResponse.slice(8, 16));
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const getClientDetails = async (token) => {
        try {
            const clientData = await fetchClientDetails(token);
            localStorage.setItem('clientid', clientData.id);
        } catch (error) {
            console.error("Error fetching client details:", error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Shopeasey</title>
            </Helmet>
            <Suspense fallback={<div className="container-fluied"><div className="row"><div className="muti-col-loader"></div></div></div>}>
                <Ecomheader />
                <MobileMenu />
                <div className=" gutter-no">
                    {/* <aside class="col-lg-3 col-xxl-sidebar page-sidebar sidebar sidebar-fixed sticky-sidebar-wrapper">
                        <NavHead/>
                    </aside> */}
                    <Megashophome />
                    <Megashopship />
                    <Megashophome section="2" />
                    <Megashopourcato categories={categories} showViewAll />
                    <Megashophome section={3} />
                    <Megashopourcato categories={categories1} showViewAll />
                    <Megashophome section={4} />
                    <FeaturedProduct />
                    <Megashophome section={5} />
                    <Megashopouroffer />
                    <Megashopourbest />
                    <Ecomfooter />
                </div> 
                 {/* {isThemeVisible && (
        <Theme 
          onCloseMegashophome={toggleMegashophomeVisibility} 
          onCloseTheme={toggleThemeVisibility} 
          isMegashophomeVisible={isMegashophomeVisible} 
        />
      )} */}
      
            </Suspense>
        </>
    );
}

export default HomePage;
