import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop"
import { Helmet } from "react-helmet";
import { addCart, addProduct, editCart } from '../Redux/Redux';
import { useDispatch, useSelector } from 'react-redux';
import ProductWrap from "../../src/product/productWrap";
const Category = () => {
  const [data, setProductData] = useState([]);
  // const [cart, setCart] = useState([]);
  const [quantities, setQuantities] = useState([]);

  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart.value)
  const wishlist = useSelector(state => state.wishlist.value)

  const navigate = useNavigate();

  const addToWishList = (productid) => {

    dispatch(addProduct(productid))
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    const storedQuantities = JSON.parse(localStorage.getItem('quantities')) || [];

    // setCart(storedCart);
    setQuantities(storedQuantities);
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axios.put("http://68.178.169.226:12090/api/v1/product/get", {
        currency: localStorage.getItem('currencyName')
      }, {

        headers: {
          // 'Authorization': 'Bearer ' +token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(cartItem.productquantity) + 1 }))
    }
    else {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(cartItem.productquantity) - 1 }))
    }
  };

  return (
    <div>
      <div>
        <Helmet>
          <title>Category</title>
        </Helmet>
      </div>
      <Ecomheader />
      <div class="container-fluid">
        <div class="row gutter-lg main-content-wrap">
          {/* <aside class="col-lg-3 sidebar sidebar-fixed sidebar-toggle-remain shop-sidebar sticky-sidebar-wrapper">
        <div class="sidebar-overlay"></div>
        <a class="sidebar-close" href="#"><i class="d-icon-times"></i></a>
        <a href="#" class="sidebar-toggle">
        <i class="fas fa-chevron-right"></i> */}
          {/* </a> */}
          {/* <div class="sidebar-content">
        <div class="sticky-sidebar" data-sticky-options="{'top': 10}">
        <div class="widget widget-collapsible">
        <h3 class="widget-title">All Categories</h3>
        <ul class="widget-body filter-items search-ul">
        <li><a href="#">Accessosries</a></li>
        <li>
        <a href="#">Bags</a>
        <ul>
        <li><a href="#">Backpacks & Fashion Bags</a></li>
        </ul>
        </li>
        <li>
        <a href="#">Electronics</a>
        <ul>
        <li><a href="#">Computer</a></li>
        <li><a href="#">Gaming & Accessosries</a></li>
        </ul>
        </li>
        <li><a href="#">For Fitness</a></li>
        <li><a href="#">Home & Kitchen</a></li>
        <li><a href="#">Men's</a></li>
        <li><a href="#">Shoes</a></li>
        <li><a href="#">Sporting Goods</a></li>
        <li><a href="#">Summer Season's</a></li>
        <li><a href="#">Travel & Clothing</a></li>
        <li><a href="#">Watches</a></li>
        <li><a href="#">Women’s</a></li>
        </ul>
        </div>
        <div class="widget widget-collapsible">
        <h3 class="widget-title">Filter by Price</h3>
        <div class="widget-body mt-3">
        <form action="#">
        <div class="filter-price-slider"></div>
        <div class="filter-actions">
        <div class="filter-price-text mb-4">Price:
        <span class="filter-price-range"></span>
        </div>
        <button type="submit" class="btn btn-dark btn-filter btn-rounded">Filter</button>
        </div>
        </form>
        </div>
        </div>
        <div class="widget widget-collapsible">
        <h3 class="widget-title">Size</h3>
        <ul class="widget-body filter-items">
        <li><a href="#">Extra Large</a></li>
        <li><a href="#">Large</a></li>
        <li><a href="#">Medium</a></li>
        <li><a href="#">Small</a></li>
        </ul>
        </div>
        <div class="widget widget-collapsible">
        <h3 class="widget-title">Color</h3>
        <ul class="widget-body filter-items">
        <li><a href="#">Black</a></li>
        <li><a href="#">Blue</a></li>
        <li><a href="#">Green</a></li>
        <li><a href="#">White</a></li>
        </ul>
        </div>
        <div class="widget widget-collapsible">
        <h3 class="widget-title">Brands</h3>
        <ul class="widget-body filter-items">
        <li><a href="#">Cinderella</a></li>
        <li><a href="#">Comedy</a></li>
        <li><a href="#">Rightcheck</a></li>
        <li><a href="#">SkillStar</a></li>
        <li><a href="#">SLS</a></li>
        </ul>
        </div>
        </div>
        </div> */}
          {/* </aside> */}
          <div class="col-lg-9 main-content">
            {/* <nav class="toolbox  sticky-toolbox sticky-content fix-top">
        <div class="toolbox-left">
        <div class="toolbox-item toolbox-sort select-box text-dark">
        <label>Sort By :</label>
        <select name="orderby" class="form-control">
        <option value="default">Default</option>
        <option value="popularity" selected="selected">Most Popular</option>
        <option value="rating">Average rating</option>
        <option value="date">Latest</option>
        <option value="price-low">Sort forward price low</option>
        <option value="price-high">Sort forward price high</option>
        <option value>Clear custom sort</option>
        </select>
        </div>
        </div>
        <div class="toolbox-right">
        <div class="toolbox-item toolbox-show select-box text-dark">
        <label>Show :</label>
        <select name="count" class="form-control">
        <option value="12">12</option>
        <option value="24">24</option>
        <option value="36">36</option>
        </select>
        </div>
        <div class="toolbox-item toolbox-layout mr-lg-0">
        <a href="shop-list-mode.html" class="d-icon-mode-list btn-layout"></a>
        <a href="shop.html" class="d-icon-mode-grid btn-layout active"></a>
        </div>
        </div>
        </nav> */}
            <div class="row  product-wrapper">
              {data.map((product, index) => (
                  <ProductWrap key={product.productid} product={product} /> 
              ))}

            </div>
            {/* <nav class="toolbox toolbox-pagination">
        <p class="show-info">Showing <span>12 of 56</span> Products</p>
        <ul class="pagination">
        <li class="page-item disabled">
        <a class="page-link page-link-prev" href="#" aria-label="Previous" tabindex="-1" aria-disabled="true">
        <i class="d-icon-arrow-left"></i>Prev
        </a>
        </li>
        <li class="page-item active" aria-current="page"><a class="page-link" href="#">1</a>
        </li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item page-item-dots"><a class="page-link" href="#">6</a></li>
        <li class="page-item">
        <a class="page-link page-link-next" href="#" aria-label="Next">
        Next<i class="d-icon-arrow-right"></i>
        </a>
        </li>
        </ul>
        </nav> */}
          </div>
        </div>
      </div>
      <Ecomfooter />
    </div>
  );
}
export default Category;