import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import MobileMenu from "../../src/navbarHead/mobileNav";
import {notification} from 'antd';
const ProfileChange=()=>{
       const navigate = useNavigate();
        const [clientDetails, setClientDetails] = useState({});
        const [mobilenumber,setMobilenumber] = useState('');
        const [errorMessage, setErrorMessage] = useState('');
        const [seconds, setSeconds] = useState(0);;
        const [responseMessage, setResponseMessage] = useState("");
        const [editMode, setEditMode] = useState(false);

  
        useEffect(() => {
          const countdownInterval = setInterval(() => {
            if (seconds > 0) {
              setSeconds(prevSeconds => prevSeconds - 1);
            }
          }, 1000);
      
          return () => clearInterval(countdownInterval);
        }, [seconds])
        const [editedClientDetails, setEditedClientDetails] = useState({
                firstname: "",
                lastname: "",
              });
        const token = localStorage.getItem("token");
      
        useEffect(() => {
      
          fetchClientDetails();
        }, [token]);
          const fetchClientDetails = async () => {
            try {
              const response = await fetch("http://68.178.169.226:12090/api/v1/login/getclientdetails", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
      
              if (response.ok) {
                const data = await response.json();
                setClientDetails(data);
                setMobilenumber(data.mobile || '');
                 // Initialize editedClientDetails with clientDetails values
          setEditedClientDetails({
            firstname: data.firstname,
            lastname: data.lastname,
          });
              } else {
                console.error("Failed to get client details");
              }
            } catch (error) {
              console.error("Error fetching client details:", error);
            }
          };

        const handleEditClientDetails = async () => {
                try {
                  const response = await fetch("http://68.178.169.226:12090/api/v1/client/editClientDetails", {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      clientid: clientDetails.id,
                      firstname: editedClientDetails.firstname,
                      lastname: editedClientDetails.lastname,
                    }),
                  });
            
                  if (response.ok) {
                    setClientDetails((prevClientDetails) => ({
                      ...prevClientDetails,
                      ...editedClientDetails,
                    }));
                      const data = await response.json();
                      if(data.status === "Success"){
                        fetchClientDetails();
                        notification.success({
                          message: data.description,
                          duration: 3, 
                        })
                        navigate(-1);
                      }else { 
                        notification.warning({
                          message: data.description,
                          duration: 3, 
                        })
                      }
          setResponseMessage(response.data.description);
                  } else {
                    console.error("Failed to update client details");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error updating client details:", error);
                  // Handle the error here
                }
              };
              const editMobileNumber = async () => {

                try {
                  const response = await fetch("http://68.178.169.226:12090/api/v1/client/mobilechange", {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: mobilenumber,
                    }),
                  });
            const data= await response.json()
            if(data.status === "Error"){
            notification.warning({
              message: data.description,
              duration: 3, 
            })
            
          }
          else{
            notification.success({
              message: data.description,
              duration: 3, 
            })
          }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              
              const handleMobileChange = (e) => {
                const newValue = e.target.value;
            
                // Check if the input consists of only digits and has a maximum length of 10 characters
                if (/^\d*$/.test(newValue) && newValue.length <= parseInt(localStorage.getItem('mobilemaxlength')))  {
                  setMobilenumber(newValue);
                  setErrorMessage('');
        
                }else {
                  // Set an error message when the input is invalid
                  setErrorMessage('Phone number must be a maximum of'  + localStorage.getItem('mobilemaxlength') + 'digits and contain only numbers.');
                }
              };
              const toggleEditMode = () => {
                setEditMode(!editMode);
              };
return(
  <div>
        <div>
            <Helmet>
                <title>Account</title>
            </Helmet>
        </div>
        <Ecomheader/>
        <MobileMenu/>
<main class="main account container homebg">
<nav class="breadcrumb-nav">
<div class="container">
<ul class="breadcrumb">
</ul>
</div>
</nav>
<div class="">
<div class="">

<div class="container-fluied  pt-5">
   
<div className="row  d-flex justify-content-center  align-self-center ">
<div class="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
<div class="form-box-header">
<h2 >Edit Profile </h2>
</div>
<div classname="accountdetails">    
<div class="tab-pane" id="account">
<form action="#" class="form">
 <div class="row">
                          <div class="col-md-12 col-sm-12 d-flex justify-content-center ">
                            {/* Show edit button only when not in edit mode */}
                            {!editMode && (
                              <button 
                                type="button" 
                                className="btn btn-dark btn-md btn-rounded" 
                                style={{marginTop:"10px",marginBottom:"15px"}} 
                                onClick={toggleEditMode} // Toggle edit mode
                              >
                                Edit
                              </button>
                            )}
                          </div>
<div class="col-md-12 col-sm-12  mt-3">
<label>Name *</label>
<input type="text" class="form-control" name="first_name" required  value={editedClientDetails.firstname}  onChange={(e) =>
                              setEditedClientDetails({
                                ...editedClientDetails,
                                firstname: e.target.value,
                              })
                            }
                            disabled={!editMode}/>
</div>
</div>
<div class="row">
                        </div>
                        {editMode && (
<div class="row">
<div class="col-md-12 col-sm-12 d-flex justify-content-center ">

<button type="button" className="btn btn-dark btn-md btn-rounded" style={{marginTop:"10px",marginBottom:"15px"}} onClick={handleEditClientDetails}>
                       Submit Changes
                      </button>
                      </div></div>
                        )}

                      {responseMessage && (
                  <div className="response-message" style={{ color: "red" }}>
                    {responseMessage}
                  </div>
                )}
<div class="row mt-3">
<div class="col-md-12 col-sm-12">
<label>Email Address *</label>
<input type="email" class="form-control" name="email" required  value={clientDetails.email || ""} disabled={editMode}/>
</div>
   </div> 
   <div class="row mt-3">
<div class="col-md-12 col-sm-12">
  <label>Mobile Number *</label>
  <input
    type="text"
    class="form-control"
    name="mobile"
    required
    value={mobilenumber}  
    onChange={handleMobileChange} 
    disabled={!editMode}
  />
  
  <div style={{ color: 'red' }}>{errorMessage}</div>
  </div> 
  </div>
  {editMode &&
  <div className="row">
  <div class="col-sm-12 col-md-12  d-flex justify-content-center edit-number mt-3">
  <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={editMobileNumber} disabled={!editMode}>
                            Edit Mobile Number
                            </button></div>
</div>
  }
</form>
</div>
</div>    
</div>
</div>
</div>

</div>
</div>
</main>   
<Ecomfooter/>
 </div>
    )
}

export default ProfileChange;