import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux'
import { transferCart, transferWishList } from "../Redux/Redux";
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';
import axios from 'axios';
import { Select } from 'antd';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";
import { Form, notification } from "antd";
import {
  faEye,
  faEyeSlash,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const RegisterPopup = () => {
  const dispatch = useDispatch()
  const token = localStorage.getItem('token');
  const mobilePrefix = localStorage.getItem('mobileprefix');
  const mobilemaxlength = localStorage.getItem('mobilemaxlength');
  const countrySelection = localStorage.getItem('selectedCountry');
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [showOtpFieldsverified, setShowOtpFieldsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [resendOtpStatus, setResendOtpStatus] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [registrationError, setRegistrationError] = useState('');
  const [mobileError, setMobileError] = useState("");
  const [emailAvailabilityMessage, setEmailAvailabilityMessage] = useState('');
  const [mobileAvailabilityMessage, setMobileAvailabilityMessage] = useState('');
  const [showOtpFieldsformobile, setShowOtpFieldsformobile] = useState(false);
  const [showOtpFieldsverifiedformobile, setShowOtpFieldsverifiedformobile] = useState(false);
  const [verificationStatusformobile, setVerificationStatusformobile] = useState('');
  const [otpformobile, setOtpformobile] = useState("");
  const [messageformobile, setMessageformobile] = useState('');
  const [resendOtpStatusformobile, setResendOtpStatusformobile] = useState('');
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showRegisterConfirmPassword, setShowRegisterConfirmPassword] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [mobileprefixforChange, setMobilePrefix] = useState('');
  const [mobilemaxlengthforChange, setMobileMaxLength] = useState('');
  const [responseMessage, setResponseMessage] = useState("");
  const [emailseconds, setEmailseconds] = useState(0);
  const [mobileseconds, setmobileseconds] = useState(0);
  
  useEffect(() => {
    const countdownIntervalmobile = setInterval(() => {
      if (mobileseconds > 0) {
        setmobileseconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdownIntervalmobile);
  }, [mobileseconds]);
  useEffect(() => {
    const countdownIntervalemail = setInterval(() => {
      if (emailseconds > 0) {
        setEmailseconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdownIntervalemail);
  }, [emailseconds]);
  const toggleRegisterPasswordVisibility = () => {
    setShowRegisterPassword(!showRegisterPassword);
  };
  const toggleRegisterConfirmPasswordVisibility = () => {
    setShowRegisterConfirmPassword(!showRegisterConfirmPassword);
  };
  const checkEmailAvailability = async (email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await axios.put(
        "http://68.178.169.226:12090/api/v1/login/checkavailableemail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.token === null && response.data.message === "email already registered") {
        
        // notification.warning({
        //   message: "Email is already registered. Choose a different email.",
        //   duration: 3, 
        // })
        // Email is not available
        setEmailAvailabilityMessage("Email is already registered. Choose a different email.");
        return false;
      } else {
        // Email is available
        setEmailAvailabilityMessage("");
        return true;
      }
    } catch (error) {
      console.error("Error checking email availability:", error);
      return false;
    }
  };
  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;


    // Update the email state
    setEmail(newEmail);
  };
  const checkMobileAvailability = async (mobile) => {
   
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);

      const response = await axios.put(
        "http://68.178.169.226:12090/api/v1/login/checkavailablemobile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.token === null && response.data.message === "mobile already registered") {
        // Mobile is not available
        setMobileAvailabilityMessage("Mobile is already registered. Choose a different mobile number.");
        notification.warning({
          message: "Mobile is already registered. Choose a different mobile number.",
          duration: 3, 
        })
        return false;
      } else {
        // Mobile is available
        setMobileAvailabilityMessage("");
        return true;
      }
    } catch (error) {
      console.error("Error checking mobile availability:", error);
      return false;
    }
  };
  const handleMobileChange = async (e) => {
    const newMobile = e.target.value;
    setMobile(newMobile);
    // Check mobile availability when the user types each character
    const isMobileAvailable = await checkMobileAvailability(newMobile);

    // Update the mobile state
 };  
  const handleVerify = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(email)) {
    // If email format is invalid, set an error message
    setRegistrationError("Please enter a valid email address.");
    notification.warning({
      message: "Please enter a valid email address.",
      duration: 3, 
    })
    return;
  }
    try {
      // Check email availability before proceeding to OTP verification
      const isEmailAvailable = await checkEmailAvailability(email);
  
      if (isEmailAvailable) {
        const response = await axios.put(
          "http://68.178.169.226:12090/api/v1/client/emailotpclient",
          {
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.data.status !== "Error") {
          setShowOtpFields(true);
          setEmailVerified(true);
        } else {
          setRegistrationError(response.data.description);
        }
        setEmailseconds(30);
      } else {
        setRegistrationError("Email is not available. Choose a different email.");
        notification.warning({
          message: "Email is not available. Choose a different email.",
          duration: 3, 
        })
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
const handleResendOtp = async () => {
  if(emailseconds!=0){
return;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

if (!emailRegex.test(email)) {
  // If email format is invalid, set an error message
  setRegistrationError("Please enter a valid email address.");
  notification.warning({
    message: "Please enter a valid email address.",
    duration: 3, 
  })
  return;
}
  try {
    // Check email availability before proceeding to OTP verification
    const isEmailAvailable = await checkEmailAvailability(email);

    if (isEmailAvailable) {
      const response = await axios.put(
        "http://68.178.169.226:12090/api/v1/client/emailotpclient",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status !== "Error") {
        setShowOtpFields(true);
        setEmailVerified(true);
      } else {
        setRegistrationError(response.data.description);
      }
      setEmailseconds(30);
    } else {
      setRegistrationError("Email is not available. Choose a different email.");
      notification.warning({
        message: "Email is not available. Choose a different email.",
        duration: 3, 
      })
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
};
  const handleVerifyformobile = async () => { 
    if (mobile.length-1 === parseInt(localStorage.getItem('mobilemaxlength'))) {
    setMobileError(`Mobile number must be ${localStorage.getItem('mobilemaxlength')} digits.`);
    notification.warning({
      message: `Mobile number must be ${localStorage.getItem('mobilemaxlength')} digits.`,
      duration: 3, 
    })
    return false;
  } else {
    setMobileError("");
  }
    try {
      // Check email availability before proceeding to OTP verification
      const isMobileAvailable = await checkMobileAvailability(mobile);
  
      if (isMobileAvailable) {
        const response = await axios.put(
          "http://68.178.169.226:12090/api/v1/client/emailandmobileotp",
          {
            mobile: mobile,
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.data.status !== "Error") {
          setShowOtpFieldsformobile(true);
        } else {
          setRegistrationError(response.data.description);
          notification.warning({
            message: response.data.description,
            duration: 3, 
          })
        }
        setmobileseconds(30);
      } else {
        // setRegistrationError("Email is not available. Choose a different email.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
const handleVerifySeller = async () => {
    try {
        const response = await axios.put(
            "http://68.178.169.226:12090/api/v1/seller/verifyseller",
            {
                otp: otp,
                mobile  : email,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        
        console.log("Password reset successful", response);
        if(response.data.status =="Success"){
            setMessage(response.data.description);
            setVerificationStatus('Success');
            setShowOtpFieldsverified(true);
          }else{
            setMessage(response.data.description);
          }
    } catch (error) {
        console.error("Error resetting password:", error);
        // Handle errors (e.g., show error message to the user)
    }
};
const handleVerifySellerformobile = async () => {
  try {
      const response = await axios.put(
          "http://68.178.169.226:12090/api/v1/seller/verifyseller",
          {
              otp: otpformobile,
              mobile  : mobile,
          },
          {
              headers: {
                  "Content-Type": "application/json",
              },
          }
      );
      
      console.log("Password reset successful", response);
      if(response.data.status =="Success"){
        setMessageformobile(response.data.description);
          setVerificationStatusformobile('Success');
          setShowOtpFieldsverifiedformobile(true);
        }else{
          setMessageformobile(response.data.description);
        }
  } catch (error) {
      console.error("Error resetting password:", error);
      // Handle errors (e.g., show error message to the user)
  }
};
const handleResendOtpformobile = async () => {
  if(mobileseconds!=0){
    return;
  }
  try {
    const response = await axios.put(
      "http://68.178.169.226:12090/api/v1/client/emailotpclient",
      {
        mobile: mobile,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.status !== "Error") {
      // Handle success, e.g., show a success message
      setResendOtpStatusformobile('Success');
    } else {
      // Handle error, e.g., show an error message
      setResendOtpStatusformobile(response.data.description);
    }
    setmobileseconds(30);
  } catch (error) {
    console.error("Error resending OTP:", error);
  }
};
const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (!firstname || !email || !mobile || !password || !confirmpassword) {
      alert("All fields are mandatory. Please fill in all the required fields.");
      return;
    }
    if (mobile.length !== (parseInt(localStorage.getItem('mobilemaxlength')))) {

      setMobileError("Mobile number must be" + (selectedCountry ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'))) + "digits.");
      return;
    } else {
      setMobileError("");
    }
    try {
      // TODO: Replace with actual API call
      const response = await fetch("http://68.178.169.226:12090/api/v1/login/registerclient", {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        firstname,
          email,
          mobile,
          password,
        }),
      });
  
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/LoginPage');
        return; // Exit early to prevent further logic execution
      }
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData.token);
        navigate("/HomePage")
        if (responseData.token != null) {
        }
        // const getClientDetailsResponse = await fetch(
        //   "http://68.178.169.226:12090/v1/login/getclientdetails",
        //   {
        //     method: "POST",
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem('token')}`,
        //     },
        //   }
        // );
        // if (getClientDetailsResponse.ok) {
        //   setShowOtpFields(true);
        //   const clientData = await getClientDetailsResponse.json();
        // } else {
        //   console.error("Failed to get client details");
        // }
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordsMatch(password === confirmpassword);
};

// Function to handle confirm password change and check if they match
const handleConfirmPasswordChange = (e) => {
    const confirmpassword = e.target.value;
    setConfirmPassword(confirmpassword);
    setPasswordsMatch(password === confirmpassword);
};
const handleLogout = () => {
  sessionStorage.clear();
navigate('/LoginPage');
};
useEffect(() => {
  const fetchCountryrDetails = async () => {
    try {
      const response = await axios.put('http://68.178.169.226:12090/api/v1/country/get', {});

      if (response.status === 200) {
        const data = response.data;
        setCountryList(data);
      } else {
        console.error('Failed to get country details');
      }
    } catch (error) {
      console.error('Error fetching country details:', error);
    }
  };

  // Call the function to fetch user details
  fetchCountryrDetails();
}, []);
useEffect(() => {
    setSelectedCountry(selectedCountry);
}, []);
const [selectedCountry, setSelectedCountry] = useState(null);
const handleCountrySelect = (selectedCountry) => {
  setMobile(null);
  const selectedCountryData = countryList.find(countryData => countryData.country === selectedCountry);
  if (selectedCountryData) {
    const { symbol, currencycode, mobilemaxlength, mobileprefix } = selectedCountryData;
      setSelectedCountry(selectedCountry);
      setMobilePrefix(mobileprefix);
    setMobileMaxLength(mobilemaxlength);
  }
  else {
    console.error(`Data for ${selectedCountry} not found in the countryList`);
  }
}
const handleGoogleLoginSuccess = async (credentialResponse) => {
  const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
  if (credentialResponseDecoded) {
    try {
      const response = await axios.put('http://68.178.169.226:12090/api/v1/login/googleauth', credentialResponseDecoded);
      if(response.status === 200){
      const body = response.data.body;
      // if(body.register){
        // navigate("/RegisterAuth", { state: { requestData: body } });
      // }
      // else{
        
      if (body.token != null) {
        localStorage.setItem("token", body.token);
        notification.success({
          message: 'Welcome to shopeasey you successfully logged in',
          duration: 3, 
        })
        
      // After successful login, make the API call to get client details
      const getClientDetailsResponse = await fetch(
        "http://68.178.169.226:12090/api/v1/login/getclientdetails",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

        const clientData = await getClientDetailsResponse.json();
        // Now you can use clientData for further actions or state updates
        localStorage.setItem('clientid',clientData.id)
        dispatch(transferWishList())
        dispatch(transferCart())
        // After successful login, close the login popup
      } else {
        setResponseMessage(body.message);
      }
      // }
    }
      // Handle response as needed
    } catch (error) {
      console.error('Error calling logindecode API:', error);
      // Handle error
    }
  }
  navigate("/HomePage");
};
useEffect(() => {
  const getClientDetailsApi = async () => {
    try {
      setLoading(true);
      const response = await fetch("http://68.178.169.226:12090/api/v1/login/getclientdetails", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client details");
      }
      
      const clientData = await response.json(); // Parse response body as JSON
      if (clientData && clientData.id) {
        navigate("/Homepage");
      }else{
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
      // Handle error: e.g., show a message to the user
    }
  };

  getClientDetailsApi();
}, [token]);
  return (
    <>
    {loading && 
    <div>
      <Helmet>
        <title>Login/Register</title>
      </Helmet>
      	 <Ecomheader/> 
      <MobileMenu />
        
        <div className="container homebg pt-5">
        <div className="row  d-flex justify-content-center  align-self-center ">
            <div class="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
            <div class="form-box-header">
            <h2>Register</h2>
            </div>
            <div className="form-box loginpage" style={{ justifyContent: "center" }}>
         
          

         <form  onSubmit={onFinish}>
         <div className="row"> 
         <div className="col-12"><h3 className="">For Email OTP, please check your spam folder.</h3></div></div>
                        
         <div className="form-group">
         <div className="row">
                          <div className="col-sm-12 input-group">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control"
                              type="text"
                              value={email}
                              onChange={handleEmailChange}
                              autoComplete="off"
                              disabled={verificationStatus === 'Success'}
                              placeholder="Email *"
                            />
                             
                            <div className="input-group-append row">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faEnvelope} />
                              </span>
                            </div>
                          </div>
                        {verificationStatus === 'Success' && ( 
                <div className="text-center w-100">
                    <span className="input-group-text">Email Verified</span>
                </div>
            )} 
                        </div> 
                        
                        </div>   
                        <div className='row'>
                            
                  
                        {!emailVerified && registrationError && (
  <div className="row">
    <div className="col-12">
      <p className="text-danger" style={{color:"red"}}>{registrationError}</p>
    </div>
  </div>
)}
                        {!showOtpFields  && (
                          <div className="col-12">
                            <button type="button"  className="btn btn-dark btn-block btn-rounded" onClick={handleVerify}>
                              Verify
                            </button>
                          </div>
                          )}
                          </div>
                        {(showOtpFields && !showOtpFieldsverified) &&  (
                             
    <div className="">
      <div className='row'>
        <div className="col-md-12 mt-5">
            <div className="form-group d-flex align-items-cente">
                <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                />
            </div>
        </div>
        </div>
        <div className='row'>
        <div className="col-md-6">
            <div className="form-group mb-0">
              <button
                type="button"
                className="btn btn-dark btn-block btn-rounded"
                onClick={handleResendOtp}
                style={{ backgroundColor: emailseconds!=0 ? "red":"" }}
                disabled={resendOtpStatus === 'Success'}
              >
                 {emailseconds!=0 ? "Resend OTP IN "+emailseconds:"Resend OTP"}
              </button>
            </div>
         </div> 

        <div className="col-md-6">
            <div className="form-group mb-0">
                <button
                    type="button"
                    className="btn btn-dark btn-block btn-rounded"
                    onClick={handleVerifySeller}
                    style={{ width: "100%" }}
                >
                    Verify OTP
                </button>
            </div>
        </div>

        </div>
        
       
        <div className='row'>
        

            </div>      
        {message && (
        <div className="row">
          <div className="col-12">
            <p className="text-danger">{message}</p>
          </div>
        </div>
      )}
    </div>
)}
{emailVerified && showOtpFieldsverified && (
       <div className="form-group mb-3">
        <div className="row">
                          <div className="col-sm-12 input-group">
                          {/* <select
      className="form-control"
      value={selectedCountry}
      onChange={(e) => handleCountrySelect(e.target.value)}
    >
      {countryList.map((countryData) => (
        <option key={countryData.countryselectionid} value={countryData.country}>
          {countryData.country}{countryData.mobileprefix}
        </option>
      ))}
    </select> */}
                      <div className="input-group-prepend">
                          <span className="input-group-text">{selectedCountry ? mobileprefixforChange : mobilePrefix}</span>
                      </div>
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={mobile}
                              onChange={handleMobileChange}
                              placeholder="Your Phone Number *"
                              autoComplete="off"
                              disabled={verificationStatusformobile === 'Success'}
                            />
                            
                {/* {verificationStatusformobile === 'Success' && ( 
                <div className="">
                    <span className="input-group-text">Mobile Verified</span>
                </div>
            )}  */}
                           
                  </div>
                  {/* { !showOtpFieldsformobile &&(
                  <div className="col-12">
                            <button type="button"  className="btn btn-dark btn-block btn-rounded" onClick={handleVerifyformobile} style={{ width: '100%' }}>
                              Verify 
                            </button>
                          </div>)} */}
                          </div>
                        </div>
)}  {mobileError && (
  <div className="error-message">{mobileError}</div>
)}
          {mobileAvailabilityMessage && (
  <p className="text-danger">{mobileAvailabilityMessage}</p>
)}
                        {/*{(showOtpFieldsformobile && !showOtpFieldsverifiedformobile) &&  (
                          <div className="">
                              <div className='row'>
        <div className="col-md-12 mt-5">
            <div className="form-group d-flex align-items-cente">
                <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={otpformobile}
                    onChange={(e) => setOtpformobile(e.target.value)}
                />
            </div>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            <div className="form-group mb-0">
                <button
                    type="button"
                    className="btn btn-dark btn-block btn-rounded"
                    onClick={handleVerifySellerformobile}
                    style={{ width: "100%" }}
                >
                    Verify OTP
                </button>
                </div>
        </div>
        <div className="col-md-6">
            <div className="form-group mb-0">
              <button
                type="button"
                className="btn btn-dark btn-block btn-rounded"
                onClick={handleResendOtpformobile}
                style={{ width: "100%", backgroundColor: mobileseconds!=0?'red':'' }}
                // disabled={resendOtpStatusformobile === 'Success'}
              >
                {mobileseconds!=0?'Resend OTP IN '+mobileseconds:'Resend OTP'}
              </button>
            </div>
          </div>      
         {messageformobile && (
        <div className="row">
          <div className="col-12">
            <p className="text-danger">{messageformobile}</p>
          </div>
        </div>
      )} 
    </div>
    </div>
)}*/}
  {emailVerified&& showOtpFieldsverified 
  // && showOtpFieldsformobile 
  &&(
    <>
                        <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-sm-12 input-group">
                            <input
                              id="text"
                              name="text"
                              className="form-control"
                              type="text"
                              placeholder="Name *"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              autoComplete="off"
                            />
                            <div className="input-group-append row">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                            </div>
                          </div>
                        </div>
                        </div>
                 
                        <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-12  input-group">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control"
                              type={showRegisterPassword ? "text" : "password"}
                              placeholder="Password *"
                              value={password}
                              onChange={handleNewPasswordChange}
                              autoComplete="off"
                            />
                             <div className="input-group-append">
                  <button
                    type="button"
                    onClick={toggleRegisterPasswordVisibility}
                    className="password-toggle-button"
                  >
                    {showRegisterPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </button>
                </div>
                          </div>
                        </div>
                        </div>
                        <div className="form-group">
                        <div className="row">
                          <div className="col-12  input-group">
                            <input
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type={showRegisterConfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password *"
                              value={confirmpassword}
                              onChange={handleConfirmPasswordChange}
                              autoComplete="off"
                            />
                             <div className="input-group-append row">
                  <button
                    type="button"
                    onClick={toggleRegisterConfirmPasswordVisibility}
                    className="password-toggle-button"
                  >
                    {showRegisterConfirmPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </button>
                </div>
                </div>
                <div className='row'>
                            {!passwordsMatch && (
                    <div className="password-mismatch-error">Passwords do not match.</div>
                )}</div>
                          </div>
                       
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button name="submit" type="submit" className="btn btn-dark btn-block btn-rounded">
                              Register
                            </button>
                          </div>
                        </div>
                        
                        </>
                        )}
                        <div className="row">
                          <div className="col-12 text-center login-text-in-registration">
                          <Link to="/LoginPage" >If you are an existing user, click here to log in !</Link>
                          </div>
                        </div>
                        {!(emailVerified&& showOtpFieldsverified && showOtpFieldsformobile)&&(
                        <div className="row">
             <div className="col-sm-12 d-flex justify-content-center">
            {/* <GoogleLogin
             className='google-login'
        onSuccess={handleGoogleLoginSuccess}
        onError={() => {
          alert('Login failed');
         
        }}
      /> */}
      </div>
      </div>
      )}
                       
                      </form>
           
        </div>
            </div>
            </div>
        
        
        </div>
        <Ecomfooter />
    </div>
      }
      </>
  );
};

export default RegisterPopup;
