import React, { useState, useEffect } from "react";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Select from "react-select";
const Billing = () => {
  const navigate = useNavigate();
  const mobileNumberPrefix = localStorage.getItem('mobileprefix');
  const [clientDetails, setClientDetails] = useState(null);
  const [fname, setfname] = useState('');
  const [companyname, setcompanyname] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [country, setcountry] = useState('');
  const [region, setregion] = useState('');
  const [address1, setaddress1] = useState('');
  const [address2, setaddress2] = useState('');
  const [city, setcity] = useState('');
  const [stateList, setStateList] = useState([]);
  const [state, setstate] = useState('');
  const [pincode, setpincode] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [email, setemail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [billingAddressAlreadyPresent, setbillingAddressAlreadyPresent] = useState(false);
  const [sameShippingAddress, setSameShippingAddress] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pincodeList, setPincodeList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const handleCountryClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setcountry(selectedValue);

    setSelectedState("");
    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  const handleStateClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setstate(selectedValue);
    setSelectedState(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchPincodes(country, selectedValue);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phonenumber.length < parseInt(localStorage.getItem('mobilemaxlength'))) {
      // Show an error message for less than 10 digits
      setErrorMessage('Please enter' + localStorage.getItem('mobilemaxlength') + 'digits.');
      return;
    }
    try {
      const response = await fetch('http://68.178.169.226:12090/api/v1/client/editbillingaddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          clientid: clientDetails.id,
          name: fname,
          companyname,
          country,
          address1,
          region,
          address2,
          city,
          state,
          pincode,
          phonenumber,
          email,
        }),
      });

      if (response.ok) {
        // Billing address updated successfully
        // You may want to show a success message or perform other actions
        // window.location.reload();
        // Update the addressBilling state with editedBillingAddress
        navigate(-1);
      } else {
        console.error('Failed to update billing address');
      }
    } catch (error) {
      console.error('Error updating billing address:', error);
    }
    if (sameShippingAddress) {
      try {
        // TODO: Replace with actual API call
        const response = await fetch("http://68.178.169.226:12090/api/v1/client/addaddress", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            clientid: clientDetails.id,
            name: fname,
            companyname,
            country,
            address1,
            region,
            address2,
            city,
            state,
            pincode,
            phonenumber,
            email,
          }),
        });

        if (response.status === 401 || response.status === 404) {
          setError('Unauthorized or Not Found');
          setLoading(false);
          return;
        }

        if (response.ok) {
          const responseData = await response.json();
          setData(responseData.data);
          setLoading(false);
          navigate('/Checkout')
        } else {
          setError('Failed to add address');
          setLoading(false);
        }
      } catch (error) {
        setError('An error occurred');
        setLoading(false);
      }
    };
  };


  useEffect(() => {
    // Function to fetch client details
    const fetchClientDetails = async () => {
      try {
        // Make the API request to get client details
        const response = await fetch(
          'http://68.178.169.226:12090/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the client details in state
          setClientDetails(data);

          // After receiving client details, make another API request
          // to fetch address billing using the id from the client details
          fetchAddressBilling(data.id);
        } else {
          console.error('Failed to get client details');
        }
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    };

    // Function to fetch address billing
    const fetchAddressBilling = async (id) => {
      try {
        // Define the payload with the client ID
        const payload = {
          id: id,
        };

        // Make the API request to get address billing with the payload
        const response = await fetch(
          'http://68.178.169.226:12090/api/v1/client/getbillingaddress',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          if (response.data !== null) {
            setbillingAddressAlreadyPresent(true);
            const data = await response.json();
            setfname(data.name)
            setcountry(data.country)
            setcompanyname(data.companyname)
            setregion(data.region)
            setaddress1(data.address1)
            setaddress2(data.address2)
            setcity(data.city)
            setstate(data.state)
            setpincode(data.pincode)
            setphonenumber(data.phonenumber)
            setemail(data.email)
            if (data.country != null) {
              fetchStates(data.country)
            }
            fetchPincodes(data.country, data.state)
            setSelectedState(data.state);
          }
        } else {
          console.error('Failed to get address billing');
        }
      } catch (error) {
        console.error('Error fetching address billing:', error);
      }
    };

    // Call the fetchClientDetails function when the component mounts
    fetchClientDetails();
  }, []);
  const handleMobileChange = (e) => {
    const newValue = e.target.value;

    // Check if the input consists of only digits and has a maximum length of 10 characters
    if (/^\d*$/.test(newValue) && newValue.length <= parseInt(localStorage.getItem('mobilemaxlength'))) {
      setphonenumber(newValue);
      setErrorMessage('');

    } else {
      // Set an error message when the input is invalid
      setErrorMessage('Phone number must be a maximum of' + localStorage.getItem('mobilemaxlength') + 'digits and contain only numbers.');
    }
  };
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12090/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);

  const fetchStates = async (country) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12090/api/v1/pincode/getstate",
        {
          country,
        }
      );

      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching the list of states:", error);
    }
  };
  const fetchPincodes = async (country, selectedState) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12090/api/v1/pincode/getpincode",
        {
          country,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  return (

    <div>
      <div>
        <Helmet>
          <title>Billing Address</title>
        </Helmet>
      </div>
      <Ecomheader />
      <main className="container homebg pt-5">
        <div className="page-content ">
          <div className="row  d-flex justify-content-center  align-self-center ">
            <div class="col-lg-5 col-md-5 col-sm-12 form-box-for-border">
              <div class="form-box-header">
                <h2>Billing Details</h2>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-12 col-sm-12">
                  <label>Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={fname != null ? fname : ''}
                    onChange={(e) => setfname(e.target.value)}

                  />
                </div>
                </div>
                <div className="row">
                <div className="col-xs-12 col-md-12 col-sm-12">
                  <label>Company Name (Optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company-name"
                    required
                    value={companyname != null ? companyname : ''}
                    onChange={(e) => setcompanyname(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Phone *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      required
                      value={phonenumber != null ? phonenumber : ''}
                      onChange={handleMobileChange}
                    />
                    <div style={{ color: 'red' }}>{errorMessage}</div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Email Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email-address"
                      required
                      value={email != null ? email : ''}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
              </div>
              <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Street Address *</label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="address1"
                      required
                      placeholder="House number and street name"
                      value={address1 != null ? address1 : ''}
                      onChange={(e) => setaddress1(e.target.value)}
                      style={{resize: "none"}}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Street Address 2*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="address2"
                      required
                      placeholder="Apartment, suite, unit, etc. (optional)"
                      value={address2 != null ? address2 : ''}
                      onChange={(e) => setaddress2(e.target.value)}
                      style={{resize: "none"}}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Country</label>
                    <select
                      className="form-control"
                      name="country"
                      required
                      value={country}
                      onClick={(e) => handleCountryClick(e.target.value)}
                    >
                      {countryList.map((country) => (
                        <option key={country.pincodeid} value={country.country}>
                          {country.country}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>State *</label>
                    <Select
            options={stateList.map((stateItem) => ({
              value: stateItem.state,
              label: stateItem.state,
            }))}
            value={selectedState ? { value: selectedState, label: selectedState } : null}
  onChange={(selectedOption) => handleStateClick(selectedOption.value)}
  isSearchable={true} // Enable search option
  placeholder={selectedState ? selectedState : "Select State"}
          />
                    {/* <select
                      className="form-control"
                      name="state"
                      required
                      value={state}
                      onClick={(e) => handleStateClick(e.target.value)}
                    >
                      {stateList.map((stateItem) => (
                        <option key={stateItem.pincodeid} value={stateItem.state}>
                          {stateItem.state}
                        </option>
                      ))}
                    </select> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>Town / City *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      required
                      value={city != null ? city : ''}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-md-12 col-sm-12">
                    <label>ZIP *</label>
                    <select
                      className="form-control"
                      name="zip"
                      required
                      value={pincode}
                      onClick={(e) => setpincode(e.target.value)}
                    >
                      {pincodeList.map((zipItem) => (
                        <option key={zipItem.pincodeid} value={zipItem.pincode}>
                          {zipItem.pincode}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>


            </div>
          </div>
          <div className="card accordion">
            {/* Your card content here */}
          </div>
          <div className="card accordion">
            {/* Your card content here */}
          </div>
          <form action="#" className="form">
            <div className="row">
              <div className="col-lg-12 ">



                
                
                
                

                {billingAddressAlreadyPresent ? "" : (
                  <div className="col-xs-6">
                    <div className="form-checkbox mb-0">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id="same-as-billing"
                        name="same-as-billing"
                        checked={sameShippingAddress}
                        onChange={() => setSameShippingAddress(!sameShippingAddress)}
                      />
                      <label
                        className="form-control-label ls-s"
                        for="same-as-billing"
                      >
                        Same as Billing Address
                      </label>
                    </div>
                  </div>
                )}
              </div>

            </div>
            <div className="row mt-5">
              <div className="col-md-12 d-flex justify-content-center">
                <div>
                  <button type="submit" className="btn btn-dark btn-rounded btn-order" onClick={handleSubmit}>
                    SUBMIT
                  </button>
                </div></div></div>

          </form>

        </div>
      </main>
      <Ecomfooter />
    </div>
  )
}
export default Billing;