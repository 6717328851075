import React, { useState, useEffect } from "react";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../header/headershop";
import axios from "axios";
import "../../src/allCategories/allcategory.css";
import { Link } from 'react-router-dom';
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";

const AllCategory = () => {
  const [categories, setCategories] = useState([]);

    useEffect(() => {
      // Fetch data from the API
      axios.put('http://68.178.169.226:12090/api/v1/category/get',{
        currency: localStorage.getItem('currencyName')
      })
        .then((response) => {
          // Assuming the API response is an array of category objects
          setCategories(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);

  return (
    <div>
      <Ecomheader/>
      <MobileMenu/>
      <div className=" ">
      {/* <aside class="col-lg-3 col-xxl-sidebar page-sidebar sidebar sidebar-fixed sticky-sidebar-wrapper">
           
           <NavHead/>
          
       </aside> */}
       {/* <div class="col-lg-9 col-xxl-content bside"> */}
    <div className="container-fluid ">
<div className="row">    
<div className="col-xs-12">
      <h2 className="title title-center mb-5">Our Categories</h2>
    </div>
{categories.map((category, index) => (
<div  key={index} class="col-xs-6 col-lg-3 mb-4">
<div class="category category-default1 category-absolute banner-radius overlay-zoom">    
<Link  to={`/MenuCategory/${category.categoryid}`} className="categoery-media"> <img className="ourcato" src={category.fileModel?.file} alt="intro-banner" /></Link>

<div class="category-content">
<h4 class="category-name font-weight-bold ls-l"><Link to={`/MenuCategory/${category.categoryid}`}>{category.categoryname}</Link>
  </h4>
</div>
</div>
</div>       
))}
</div>
</div> 
<Ecomfooter/>
{/* </div> */}
</div>
</div> 
  );
};

export default AllCategory;
